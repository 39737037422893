@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&family=Sen:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}

@layer base {
  body {
    @apply bg-gray-800;
  }
}
::-webkit-scrollbar {
  background-color: transparent;
}